
import { defineComponent, reactive, toRaw } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'
import BaseInput from '@/components/base/BaseInput.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'

export default defineComponent({
  components: { BaseInput, LanguageSelect },
  setup () {
    const { sendPasswordResetEmail } = useAuth()

    const form = reactive({
      email: ''
    })

    const attemptRecoverPassword = () => {
      const { email } = toRaw(form)

      sendPasswordResetEmail(email)
    }

    return {
      attemptRecoverPassword,
      form
    }
  }
})
