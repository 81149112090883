<template>
  <div class="w-full sm:w-4/5 md:3/4 2xl:w-1/2 pb-8">
    <div class="text-center my-10">
      <h1>{{ $t('page.recovery.sentence.forgot_password') }}</h1>
      <h3 class="font-sans md:font-semibold text-base md:text-lg my-3 text-gray-600">
        {{ $t('page.recovery.sentence.enter_registered_email') }}
      </h3>
    </div>

    <div>
      <form @submit.prevent="attemptRecoverPassword" class="my-12 space-y-24">
        <base-input
            :label="$t('form.label.email')"
            name="email"
            type="email"
            v-model="form.email"
            autocomplete="username"
            left-icon="far fa-envelope" />
        
        <div class="w-full my-6 md:my-12">
          <button
            type="submit"
            class="btn btn-primary btn-lg w-full"
          >
            {{ $t('page.recovery.action.send_email') }}
          </button>
        </div>
      </form>
      <div>
        <router-link :to="{ name: 'login' }" class="font-bold text-blue-600">
          {{ $t('page.recovery.action.back_to_login') }}
        </router-link>
      </div>
    </div>
  </div>
  <div class="absolute top-0 right-0 m-3">
    <language-select clean />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRaw } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'
import BaseInput from '@/components/base/BaseInput.vue'
import LanguageSelect from '@/components/settings/languageSelect.vue'

export default defineComponent({
  components: { BaseInput, LanguageSelect },
  setup () {
    const { sendPasswordResetEmail } = useAuth()

    const form = reactive({
      email: ''
    })

    const attemptRecoverPassword = () => {
      const { email } = toRaw(form)

      sendPasswordResetEmail(email)
    }

    return {
      attemptRecoverPassword,
      form
    }
  }
})
</script>
